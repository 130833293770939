const makeAjaxCall = async (input, wpAjaxObj) => {
  // eslint-disable-line
  const data = new FormData(); // eslint-disable-line

  data.append("action", "ajaxmodal");
  data.append("nonce", wpAjaxObj.ajax_nonce);
  data.append("username", input.username);
  data.append("password", input.password);

  try {
    const response = await fetch(wpAjaxObj.ajaxurl, {
      method: "POST",
      credentials: "same-origin",
      body: data,
    });

    return await response.json();
  } catch (error) {
    return error;
  }
};

const closeForm = () => {
  const modalWrapper = document.querySelector(".modal-wrapper");
  modalWrapper.classList.remove("show");
};

const clearForm = (form) => {
  form.reset();
};

const displayErrors = (data, form) => {
  const errorDiv = form.getElementByClassName("form-status")[0];

  form.classList.add("form-error");

  errorDiv.style.display = "block";
  errorDiv.innerHTML = data.message;

  clearForm(form);
};

const clearErrors = (form) => {
  if (!form.classList.contains("form-error")) {
    return;
  }

  const userNameInput = form.querySelector("input#username");
  const errorDiv = document.getElementById("form-status");

  userNameInput.addEventListener("input", () => {
    if (!form.classList.contains("form-error")) {
      return;
    }

    form.classList.remove("form-error");
    errorDiv.innerHTML = "";
    errorDiv.style.display = "none";
  });
};

const showSpinner = (form) => {
  const spinner = form.querySelector(".login-form-spinner");
  const submitBtn = form.querySelector(".submit-form-text");
  submitBtn.style.display = "none";
  spinner.style.display = "inline-block";
};

const hideSpinner = (form) => {
  const spinner = form.querySelector(".login-form-spinner");
  const submitBtn = form.querySelector(".submit-form-text");
  spinner.style.display = "none";
  submitBtn.style.display = "inline-block";
};

const replaceText = (text, form) => {
  const submitBtn = form
    .querySelector(".login-form-submit")
    .querySelector(".submit-form-text");

  submitBtn.innerHTML = text;
};

const activateAction = (form, wpAjaxObj) => {
  form.addEventListener("submit", (e) => {
    e.preventDefault();

    const dataForm = new FormData(form);
    const sendedData = {
      username: dataForm.get("username"),
      password: dataForm.get("password"),
    };

    showSpinner(form);

    makeAjaxCall(sendedData, wpAjaxObj).then((data) => {
      if (!data.loggedin) {
        displayErrors(data, form);
        clearErrors(form);
      } else {
        replaceText(data.message, form);

        window.location.replace(wpAjaxObj.redirecturl);

        setTimeout(() => {
          closeForm();
          clearForm();
        }, 4000);
      }
      hideSpinner(form);
    });
  });
};

window.addEventListener("load", () => {
  const submittedForms = document.querySelectorAll("form.form-modal-login"); // eslint-disable-line
  const wpAjaxObj = ajax_modal_forms_object; // eslint-disable-line

  if (
    !submittedForms ||
    Object.keys(submittedForms).length === 0 ||
    !wpAjaxObj ||
    Object.keys(wpAjaxObj).length === 0
  ) {
    return;
  }

  submittedForms.forEach((submittedForm) => {
    activateAction(submittedForm, wpAjaxObj);
    clearErrors(submittedForm);
  });
});
